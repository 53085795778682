import React, { useState } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Form, Button } from 'react-bootstrap';
import * as Sentry from '@sentry/gatsby';

import Api from '../lib/Api';
import Layout from "../components/layout";
import SEO from "../components/seo";
import ConsentModal from '../components/survey/consentModal';
import SurveyForm from '../components/survey';
import styles from '../components/styles/upload.module.scss';

const AmtUploadPage = () => {
    const { t } = useTranslation('upload');
    const [success, setSuccess] = useState(false);
    const [amtCode, setAmtCode] = useState('');
    const [amtCodeSubmitted, setAmtCodeSubmitted] = useState(false);
    const [amtCodeSubmitEnabled, allowAmtCodeSubmit] = useState(true);

    const handleAmtCodeSubmit = async (e) => {
        e.preventDefault();
        allowAmtCodeSubmit(false);

        try {
            await Api.postAmtVisit({
                'amt_code': amtCode,
            });
            setAmtCodeSubmitted(true);
        } catch (err) {
            console.log(err);
            allowAmtCodeSubmit(true);
        }
    };

    return (
        <Layout>
            <SEO title={t('title')} />

            <main className={styles.main}>
                <Container className={styles.container}>
                    {success ? (
                        <div>
                            <p className="text-center my-5">
                                <span className="h1">{t('success_1')}</span>
                                <br /><br />
                                <span className="h2">{t('success_2')}</span>
                            </p>
                        </div>
                    ) : (
                        <>
                            <h1>Amazon Mechanical Turk Survey</h1>

                            <p>
                                Dear Amazon Mechanical Turk User,
                            </p>

                            {!amtCodeSubmitted ? (
                                <p>
                                    Thank you for agreeing to donate your smartphone GPS data. Please enter in your AMT 
                                    worker ID to proceed, to provide the GPS data from your smartphone.
                                </p>
                            ) : (
                                <>
                                    <p>
                                        Please answer the questions below and follow the instructions for donating
                                        your smartphone GPS data.
                                    </p>
                                    <p>
                                        Once you have clicked Submit, your data will undergo a quality check, which may 
                                        take up to two weeks. After this quality check is done, we will follow up with 
                                        you through Amazon Mechanical Turk.
                                    </p>
                                </>
                            )}

                            <hr className="my-4" />

                            {!amtCodeSubmitted ? (
                                <Form className={styles.form} onSubmit={handleAmtCodeSubmit}>
                                    <Form.Group controlId="input-amt">
                                        <Form.Label><strong>Enter your AMT worker ID to start</strong> <small>(required)</small></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={amtCode}
                                            onChange={e => setAmtCode(e.target.value)}
                                        />
                                    </Form.Group>
                    
                                    <div className="mt-4 text-center">
                                        <Button 
                                            variant="primary" 
                                            type="submit" 
                                            block 
                                            disabled={!amtCodeSubmitEnabled || amtCode.trim().length === 0}>
                                            Next
                                        </Button>
                                    </div>
                                </Form>
                            ) : (
                                <SurveyForm formType="amt" amtCode={amtCode} onSuccess={() => setSuccess(true)} />
                            )}
                        </>
                    )}
                </Container>
            </main>

            {amtCodeSubmitted && <ConsentModal />}
        </Layout>
    );
};

export default AmtUploadPage;
